
import { Component, Vue, Watch, Prop, } from 'vue-property-decorator'

@Component
export default class DialogImageComponent extends Vue {
  @Prop({ required: true, }) readonly showDialog!: string
  @Prop({ required: false, }) readonly image!: number

  private dialog = false

  @Watch('showDialog')
  show (val: boolean): void {
    if (val) {
      this.dialog = true
    } else {
      this.dialog = false
    }
  }
  @Watch('dialog')
  dialogClose (): void {
    if (this.dialog === false) {
      this.$emit('close')
    }
  }

  private emitEvent () {
    this.dialog = false
    this.$emit('action')
  }

  get getImgApp () {
    switch (this.image) {
    case 0:
      return 'step1.svg'
    case 1:
      return 'step2.1.svg'
    case 2:
      return 'step2.2.svg'
    case 3:
      return 'step1.1.svg'
    case 4:
      return 'step3.svg'

    default:
      return 'asana.svg'
    }
  }
}
