
import { Component, Vue, } from 'vue-property-decorator'
import DialogImage from '@/components/DialogImage/Index.vue'

@Component({
  components: {
    DialogImage,
  },
})
export default class AddApplicationsComponent extends Vue {
  private image_selected = 0
  private dialog = false

  private closeDialog () {
    this.dialog = false
  }

  private goForm () {
    this.$router.push('/startup/applications/form')
  }

  private openDialog (element: number) {
    this.image_selected = element
    this.dialog = true
  }
}
